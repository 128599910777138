import { render, staticRenderFns } from "./5-ExerciseCourse.vue?vue&type=template&id=bf903d92&scoped=true"
import script from "./5-ExerciseCourse.vue?vue&type=script&lang=js"
export * from "./5-ExerciseCourse.vue?vue&type=script&lang=js"
import style0 from "./5-ExerciseCourse.vue?vue&type=style&index=0&id=bf903d92&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf903d92",
  null
  
)

export default component.exports