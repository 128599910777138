<template>
  <!-- 我们的海内外的学生，都拿到了这些公司的Offer -->
  <div class="g-section">
    <div class="g-header">
      <h3>我们的海内外的学生，可以拿到这些公司的Offer</h3>
      <p>坚实的口碑，真实的offer案例，速成刷题踏入世界主流IT公司</p>
    </div>
    <div class="container">
      <div class="hz-cells">
        <img alt="" src="@/assets/images/scb/logos.png" />
        <!-- <img alt="" src="../../assets/images/scb/course_logos.png"/> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "offer",
};
</script>
<style scoped>
@import url("../../../assets/css/courses/common.css");
</style>
