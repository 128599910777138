<template>
  <!-- 求职面试刷题班 -->
  <div class="">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">求职面试刷题班</div>
            <div class="sch-desc">
              算法技巧套路方法归纳总结，模版直接套用速成去面试<br />
              <!--              LeetCode题目千题一道道讲解，方法技巧学以致用，答疑服务解决一切难题-->
              所有题型一应俱全，全部掌握足以突破国内外公司算法面试
            </div>
            <ul class="leet-date">
              <li>
                <div class="val">18 <span>类</span></div>
                <div class="txt">题型总结</div>
              </li>
<!--              <li>-->
<!--                <div class="val">90 <span>天</span></div>-->
<!--                <div class="txt">答疑服务</div>-->
<!--              </li>-->
              <li>
                <div class="val">100 <span>+</span></div>
                <div class="txt">核心考点</div>
              </li>
              <li>
                <div class="val">200 <span>+</span></div>
                <div class="txt">章节内容</div>
              </li>
              <li>
                <div class="val">400 <span>道</span></div>
                <div class="txt">LC课后习题</div>
              </li>
<!--              <li>-->
<!--                <div class="val">5000 <span>+</span></div>-->
<!--                <div class="txt">学员好评</div>-->
<!--              </li>-->
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 & 免费试听</div>
            </div>
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>
        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div
              	class="price-tip"
                style="
                  color: rgba(0, 0, 0, 0.65);
                  text-align: center;
                  font-size: 12px;
                  position: absolute;
                  right: 82px;
                  top: 15px;
                "
              >
                立即报名，立即开始！报名即学，不浪费一分一秒！
              </div>
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div
                    class="prices"
                    style=""
                    v-if="comboData.length > 0"
                  >
                    <span style="font-size: 28px; color: #de1306">
                      ${{
                        comboData[0].usddiscountPrice != 0
                          ? comboData[0].usddiscountPrice
                          : comboData[0].usdprice
                      }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{
                        comboData[0].discountPrice != 0
                          ? comboData[0].discountPrice
                          : comboData[0].price
                      }}</span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元分期付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>
                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>
        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
              />
              <div v-if="stickyToggle" class="price" style="right: -20px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{
                    comboData[0].usddiscountPrice > 0
                      ? comboData[0].usddiscountPrice
                      : comboData[0].usdprice
                  }}</span
                >
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{
                    comboData[0].discountPrice > 0
                      ? comboData[0].discountPrice
                      : comboData[0].price
                  }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <div class="g-section mrt36" style="padding-top: 72px">
            <div class="g-header">
              <h3>让你刷题可以事半功倍，速成算法去公司面试课程</h3>
              <p>
                3000题实战经验提炼总结的做题套路，方法技巧，模板应用以及follow
                up
              </p>
            </div>
            <div class="container">
              <el-row :gutter="20" class="bb-rows">
                <el-col :span="6">
                  <div class="bb-card hoverUp">
                    <div class="icon">
                      <img src="../../assets/images/qz_shuati/bb1.png" alt="" />
                    </div>
                    <div class="tt">事半功倍</div>
                    <div class="desc">
                      大量方法技巧一点就透<br />做一题等于10题
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="bb-card hoverUp">
                    <div class="icon">
                      <img src="../../assets/images/qz_shuati/bb2.png" alt="" />
                    </div>
                    <div class="tt">一天10题</div>
                    <div class="desc">初学者就可以<br />一天10天轻松刷题</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="bb-card hoverUp">
                    <div class="icon">
                      <img src="../../assets/images/qz_shuati/bb3.png" alt="" />
                    </div>
                    <div class="tt">面试突击</div>
                    <div class="desc">面试时间紧张<br />速成算法去面试</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="bb-card hoverUp">
                    <div class="icon">
                      <img src="../../assets/images/qz_shuati/bb4.png" alt="" />
                    </div>
                    <div class="tt">套路模版</div>
                    <div class="desc">
                      各种套路技巧总结<br />做题模板拿来就用
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="g-section zkk-section">
            <div class="container">
              <div class="g-header">
                <h3>
                  科学完善的解题思路分析，结合一线面试的考核点评，让你学的更快、更扎实
                </h3>
                <p>
                  每项技巧套路，直接对应LeetCode实战题型，帮你快速理清思路，短期提升
                </p>
              </div>
              <div class="zkk-main">
                <el-row :gutter="56">
                  <el-col :span="12">
                    <div class="zzk-hd">题型技巧总结</div>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <div class="zzk-sm-hd">题型分类</div>
                        <div class="zzk-item">回溯法</div>
                        <div class="zzk-item">动态规划</div>
                        <div class="zzk-item">设计</div>
                      </el-col>
                      <el-col :span="12">
                        <div class="zzk-sm-hd">考点分类</div>
                        <div class="zzk-item">回溯三大题型——排列</div>
                        <div class="zzk-item">回溯三大题型——子集</div>
                        <div class="zzk-item">回溯三大题型——组合</div>
                        <div class="zzk-item">模版和值传递</div>
                        <div class="zzk-item">回溯法实现题</div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12" class="zkk-odd">
                    <div class="zzk-hd">LeetCode实战题型</div>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <div class="zzk-sm-hd">LeetCode题目</div>
                        <div class="zzk-item">377. Combination Sum IV</div>
                        <div class="zzk-item">93. Restore IP Addresses</div>
                        <div class="zzk-item">47. Permutations II</div>
                      </el-col>
                      <el-col :span="12">
                        <div class="zzk-sm-hd">考频提示</div>
                        <div class="zzk-item">很少考</div>
                        <div class="zzk-item">很重要</div>
                        <div class="zzk-item">重要</div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>面试套路方法模版+LeetCode最终分类顺序表</h3>
              <p>做题套路，方法技巧，模版应用，follow up</p>
            </div>
            <div class="container">
              <div class="mmc-main">
                <div class="mmc-box mmc-1">
                  <div class="mmc-hd">面试各种follow up</div>
                  <div class="mmc-bd">
                    大量只有面试中出现，例子：给一堆点<br />
                    求有多少个正方形，数据量过大怎么办
                  </div>
                </div>
                <div class="mmc-box mmc-2">
                  <div class="mmc-hd">做题方法技巧</div>
                  <div class="mmc-bd">
                    3000题总结的各种方法技巧<br />
                    遇见什么题马上想出答案<br />
                    经验总结方法，独家拥有
                  </div>
                </div>
                <div class="mmc-box mmc-3">
                  <div class="mmc-hd">最终版本的《LC分类顺序表》</div>
                  <div class="mmc-bd">
                    最细致的分类顺序表，400题总结<br />
                    每种题型细分对应题目，科学快速的刷题
                  </div>
                </div>
                <div class="mmc-box mmc-4">
                  <div class="mmc-hd">补充面试&LeetCode算法</div>
                  <div class="mmc-bd">
                    flood fill，sweep line，sliding window等<br />
                    常见算法详细解释<br />
                    只有面试中会出现的“匈牙利算法”等
                  </div>
                </div>
                <div class="mmc-box mmc-5">
                  <div class="mmc-hd">所有大题型再细分</div>
                  <div class="mmc-bd">
                    每种大题型下可以继续细分成其他类别<br />每类别都有特定方法
                  </div>
                </div>
                <div class="mmc-box mmc-6">
                  <div class="mmc-hd">科学做题模版</div>
                  <div class="mmc-bd">
                    为初学者准备，遇见题添加内容就行<br />简单无脑，直接套用
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="g-section mrt36">
            <div class="container">
              <div class="xldx-main">
                <div class="g-header">
                  <h3>
                    自己刷题效率低下慢，掌握方法技巧可以事半功倍，LeetCode讲解再上一层楼
                  </h3>
                  <p>面试刷题技巧套路早晚都要掌握，我们已经总结归纳完毕</p>
                </div>
                <div class="xldx-row">
                  <div class="xldx-cell xldx-1">
                    <div class="icon">
                      <img
                        src="../../assets/images/qz_shuati/bg-icon1.png"
                        alt=""
                      />
                    </div>
                    <div class="xl-tit">自己独自低效刷题</div>
                    <div class="nn">01</div>
                  </div>
                  <div class="xldx-cell xldx-2">
                    <div class="icon">
                      <img
                        src="../../assets/images/qz_shuati/bg-icon2.png"
                        alt=""
                      />
                    </div>
                    <div class="xl-tit">《求职面试刷题班》</div>
                    <div class="xl-desc">
                      各种套路模版科学总结<br />
                      一个技巧快速对应多个题型<br />
                      瞬间想出做题思路
                    </div>
                    <div class="nn">02</div>
                  </div>
                  <div class="xldx-cell xldx-3">
                    <div class="icon">
                      <img
                        src="../../assets/images/qz_shuati/bg-icon3.png"
                        alt=""
                      />
                    </div>
                    <div class="xl-tit">《LeetCode千题视频讲解》</div>
                    <div class="xl-desc">
                      每题中文带你快速分析题意，提炼考点<br />
                      解题过程就是最优解，快速理解<br />
                      一行代码一行代码带你写，逐行分析解读<br />
                      一题多解，最优解都有<br />
                      时间复杂度，空间复杂度分析
                    </div>
                    <div class="nn">03</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="g-section gnzj-section">
            <div class="g-header">
              <h3>不需要自己归纳总结，现成的技巧套路模版帮你节省大量时间</h3>
              <p>我们的课程，省去了大量踩坑和总结的时间</p>
            </div>
            <div class="container">
              <el-row :gutter="28" class="gn-rowss">
                <el-col :span="6">
                  <div class="gn-card">
                    <div class="gn-card-inner">
                      <div class="tt">技巧套路时间</div>
                      <div class="desc">
                        做大量题才能产生做题感觉<br />才能提炼技巧套路
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="gn-card org">
                    <div class="gn-card-inner">
                      <div class="tt">方法类型总结时间</div>
                      <div class="desc">
                        一个方法对应哪些类型题<br />花大量时间自己总结
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="gn-card blue">
                    <div class="gn-card-inner">
                      <div class="tt">查找答案时间</div>
                      <div class="desc">
                        网上和Discuss答案众多<br />需要自己筛选最优解通用解
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="gn-card blue2">
                    <div class="gn-card-inner">
                      <div class="tt">理解答案时间</div>
                      <div class="desc">
                        即使有答案<br />2个小时理解一道题<br />这里10分钟搞定
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>十八大模块的系统分类，200+小节超详细的技巧方法总结</h3>
              <p>
                学习并掌握面试题型的底层逻辑，训练实战的套路打法，才是你的正确选择
              </p>
            </div>
            <div class="container">
              <div class="g-photo">
                <img src="../../assets/images/qz_shuati/jialg.png" alt="" />
              </div>
            </div>
          </div>
          <!--          <code-style></code-style>-->
          <!--          <div class="g-section mrt36">-->
          <!--            <div class="g-header">-->
          <!--              <h3>Java 语言讲解 并提供三大主流语言解法答案</h3>-->
          <!--              <p>Java语言讲解，IDE中书写，提供C++，Python对应代码</p>-->
          <!--            </div>-->
          <!--            <div class="container">-->
          <!--              <el-row type="flex" class="ja-rows" align="center">-->
          <!--                <el-col :span="8">-->
          <!--                  <img src="../../assets/images/timu/java.png" alt="" />-->
          <!--                  <div class="txt">Java语言</div>-->
          <!--                </el-col>-->
          <!--                <el-col :span="8">-->
          <!--                  <img src="../../assets/images/timu/cc.png" alt="" />-->
          <!--                  <div class="txt">C++</div>-->
          <!--                </el-col>-->
          <!--                <el-col :span="8">-->
          <!--                  <img src="../../assets/images/timu/jj.png" alt="" />-->
          <!--                  <div class="txt">Python</div>-->
          <!--                </el-col>-->
          <!--              </el-row>-->
          <!--            </div>-->
          <!--          </div>-->
          <!-- 千题录播讲解随时观看，代码升级讲师互动 -->
          <thousands-questions></thousands-questions>

          <!-- 我们的海内外的学生，都拿到了这些公司的Offer -->
          <offer></offer>
          <free-system></free-system>
          <!-- 价格方案 -->
          <!-- yyh-section -->
<!--          <div class="g-section" id="discountPrice">-->
<!--            <a name="tt-price"> </a>-->
<!--            <div class="g-header">-->
<!--              <h3>价格方案</h3>-->
<!--            </div>-->
<!--            <div>-->
              <!-- <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div class="gg-card">
                    <div
                      class="gg-yh-cell gg-jc-cell"
                      @click="Pcselect('1')"
                      :class="pp[0]"
                      v-if="comboData.length > 0"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ¥{{
                            comboData[0].discountPrice > 0
                              ? comboData[0].discountPrice
                              : comboData[0].price || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ${{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[0].discountPrice != 0"
                        >
                          原价：¥{{ comboData[0].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[0].discountPrice == 0"
                        >
                          基础版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          题型技巧总结，各种套路方法模版<br />
                          LeetCode 题目道道讲解，速成刷题去面试
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>学习周期：8周
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-gj-cell"
                      @click="Pcselect('2')"
                      :class="pp[1]"
                      v-if="comboData.length > 1"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ¥{{
                            comboData[1].discountPrice > 0
                              ? comboData[1].discountPrice
                              : comboData[1].price || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ${{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[1].discountPrice != 0"
                        >
                          原价：¥{{ comboData[1].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[1].discountPrice == 0"
                        >
                          高级版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          题型技巧总结，各种套路方法模版<br />
                          LeetCode 题目道道讲解，速成刷题去面试
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>学习周期：8周
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-qj-cell"
                      @click="Pcselect('3')"
                      :class="pp[2]"
                      v-if="comboData.length > 2"
                    >
                      <div class="gg-yh-grid">
                        <div class="gg-price">
                          ¥{{
                            comboData[2].discountPrice > 0
                              ? comboData[2].discountPrice
                              : comboData[2].price || 0
                          }}
                        </div>
                        <div class="gg-y-price">
                          ${{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice || 0
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[2].discountPrice != 0"
                        >
                          原价：¥{{ comboData[2].price }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[2].discountPrice == 0"
                        >
                          旗舰版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          题型技巧总结，各种套路方法模版<br />
                          LeetCode 题目道道讲解，速成刷题去面试
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>学习周期：8周
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >LC版本不同，有效期不同
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >答疑服务：购买3天内，添加客服微信<br /><span
                              style="padding-left: 19px"
                              >转发海报后即可进群</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="gaoduan-yh-popover">
                    <div class="sk-cells">
                      <div class="sk-heading">
                        <div style="width: 20%">LC题目数量</div>
                        <div style="width: 15%">有效期</div>
                        <div style="width: 10%">答疑</div>
                        <div style="width: 15%">阶段测试</div>
                        <div style="width: 15%">电话监督</div>
                        <div style="width: 15%">价格RMB</div>
                        <div style="width: 15%">选择</div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('1')"
                        :class="{ active: Pselect === '1' }"
                        v-if="comboData.length > 0"
                      >
                        <div>仅刷题班</div>
                        <div>8周</div>
                        <div>{{ comboData[0].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[0].discountPrice ||
                            comboData[0].price ||
                            0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="1"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('2')"
                        :class="{ active: Pselect === '2' }"
                        v-if="comboData.length > 1"
                      >
                        <div>+ 1-600题</div>
                        <div>60天</div>
                        <div>{{ comboData[1].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[1].discountPrice ||
                            comboData[1].price ||
                            0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="2"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        @click="Pcselect('3')"
                        :class="{ active: Pselect === '3' }"
                        v-if="comboData.length > 2"
                      >
                        <div>+ 1-600题</div>
                        <div>120天</div>
                        <div>{{ comboData[2].qaDuration }}天</div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div>
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].phoneSupervision"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger">
                          {{
                            comboData[2].discountPrice ||
                            comboData[2].price ||
                            0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="3"></el-radio>
                        </div>
                      </div>
                    </div>
                    <div class="sk-tips">
                      <span class="text-warin"
                        >*点击选中套餐，可立即下单订购</span
                      >
                      <span class="tag">支持花呗分期</span>
                      <span class="tag">支持美元分期付款</span>
                    </div>
                    <div class="skill-yh-foot">
                      <el-button type="warning" plain @click="buyCombo"
                        >立即购买</el-button
                      >
                    </div>
                  </div>
                </div>

              </div> -->
<!--              <div class="container" v-if="comboData.length >= 3">-->
<!--                <div class="gg-yh-panel sc-yh-panel clearfix">-->
<!--                  <div class="gg-card">-->
<!--                    <el-row :gutter="80">-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb base">-->
<!--                            <div class="gg-base">基础版</div>-->
<!--                            <div class="gg-text">求职面试刷题班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>题型技巧总结</li>-->
<!--                              <li>课程目录1-9课内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[0].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[0].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[0].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[0].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[0];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb upgrade">-->
<!--                            <div class="gg-base">提高版</div>-->
<!--                            <div class="gg-text">求职面试刷题班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>题型技巧总结</li>-->
<!--                              <li>课程目录10-18课内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[1].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[1].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[1].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[1].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[1];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb vip">-->
<!--                            <div class="gg-base">旗舰版</div>-->
<!--                            <div class="gg-text">求职面试刷题班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>题型技巧总结</li>-->
<!--                              <li>课程目录全部内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[2].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[2].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[2].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[2].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[2];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                    </el-row>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                      v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="24">
                        <div class="gg-yh-cell">
                          <div
                              :class="[
                              comboData.length > 1
                                ? 'gg-yh-grid red'
                                : 'gg-yh-grid',
                            ]"
                          >
                            <div class="gg-price">
                              ${{ comboData[0].usdprice }}
                            </div>
                            <div class="gg-y-price">
                              ￥{{ comboData[0].price }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              立即报名，立即开始，录播模式<br />不浪费一分一秒，立刻学习
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >求职面试刷题班
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >算法技巧，套路方法，归纳总结
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >全部掌握足以突破国内外公司算法面试
                              </li>
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >90天答疑服务-->
<!--                              </li>-->
                              <li>
                                <i class="iconfont icon-dui"></i
                                >{{ comboData[0].qaDuration / 30 }}个月有效期
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                  type="buy"
                                  @click="
                                  selectCombo = comboData[0];
                                  buyCombo();
                                "
                              >立即购买</el-button
                              >
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    :span="12"
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="5"></presales-consultation>
        </div>
        <courseMeal v-if="TabActiveIndex == 'course_meal'" id="5"></courseMeal>
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "@/utils/courseMix";
import Menus from "@/components/course/menu";
import CourseDetail from "@/components/course/courseDetail";
import courseDatas from "@/utils/courseDatas";
import presalesConsultation from "./components/presalesConsultation";
import codeStyle from "./components/codeStyle";
import thousandsQuestions from "./components/thousandsQuestions";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import polyVideo from "./components/polyVideo";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import helpClass from "./components/help";
import pageStickySelect from "./components/pageStickySelect";
export default {
  components: {
    leetDialog,
    Menus,
    CourseDetail,
    codeStyle,
    thousandsQuestions,
    offer,
    freeSystem,
    polyVideo,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
  },
  mixins: [courseMix, courseDatas],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "求职面试刷题班",
      videoShow: false,
      dr: 5,
      br: 5,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/qz_shuati/banner.jpg"),
      form: {
        region: "v1",
      },
      pp: ["ac1", "ac2", "ac3"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课目录", index: "2", tag: "course_content" },
        { name: "用户评价", index: "3", tag: "course_comment" },
        { name: "售前咨询", index: "4", tag: "course_question" },
      ],
      recommendClasses: [], //相关课程推荐byzuhuimin
      helpVisible: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    Pcselect(n) {
      this.Pselect = n;
      console.log(n);
      if (n == "1") {
        this.pp = ["ac1", "ac2", "ac3"];
      } else if (n == "2") {
        this.pp = ["ac3", "ac1", "ac2"];
      } else {
        this.pp = ["ac2", "ac3", "ac1"];
      }
      this.selectCombo = this.comboData[parseInt(n) - 1];
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
  },
};
</script>
<style scoped>
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/5-ExerciseCourse.css");
</style>
