<template>
  <div class="g-section qb-section">
    <div class="g-header">
      <h3>录播讲解随时观看，代码升级讲师互动</h3>
      <p>提问题，提建议，更新代码和视频</p>
    </div>
    <div class="container">
      <el-row>
        <el-col :span="8">
          <div class="sv-box sv1 hoverUp">
            <div class="hd">代码版本不断升级</div>
            <div class="bd">
              讲师⻓期维护代码<br />
              持续迭代更新版本<br />
              这些对你真的很有用
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="sv-box sv2 hoverUp">
            <div class="hd">课程随时随地观看</div>
            <div class="bd">
              学习时间自由灵活<br />
              闲暇空余均可利用<br />
              我们明白你的时间很重要
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="sv-box sv3 hoverUp">
            <div class="hd">讲师互动问答</div>
            <div class="bd">
              评论给讲师留言<br />
              讲师进行回复互动<br />
              建议问答随时提出
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "thousandsQuestions",
};
</script>
<style scoped>
@import url("../../../assets/css/courses/common.css");
</style>
