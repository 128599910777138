<template>
  <!-- 代码风格 -->
  <div class="g-section">
    <div class="g-header">
      <h3>代码风格严谨规范，每道题思路重要性进行点评</h3>
      <p>规范的代码细节，符合大厂面试中的隐形要求，避免面试中的那些“坑”</p>
    </div>
    <div class="container">
      <div class="g-photo">
        <img alt="" src="@/assets/images/leet/pc.png" />
        <div class="ga-tip">注：SQL题目不属于算法题不做讲解</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "codeStyle",
};
</script>
<style scoped>
@import url("../../../assets/css/courses/common.css");

.description {
  font-size: 14px;
  text-align: center;
}
</style>
