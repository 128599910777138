<template>
  <div>
    <div class="course_meal_title">优惠组合套餐</div>
    <div class="course_meal_detail">为你量身定制的专属套餐优惠套餐</div>
    <div class="mealList">
      <div class="mealContainer" v-for="(item, index) in dataList" :key="index">
        <div class="singleMeal mealA">
          <img :src="item.courseClassVOs[0].cover" alt="" />
          <div class="className">
            {{ item.courseClassVOs[0].className }}
          </div>
          <div class="qualityTime">
            有效期：
            <div class="qtimeDetail red">
              {{ item.courseClassVOs[0].currentDuration }}天
            </div>
          </div>
        </div>
        <div class="plus">
          <img src="../../../assets/images/beimei/plus.png" alt="" />
        </div>
        <div class="singleMeal mealB">
          <img :src="item.courseClassVOs[1].cover" alt="" />
          <div class="className">
            {{ item.courseClassVOs[1].className }}
          </div>
          <div class="qualityTime">
            有效期：
            <div class="qtimeDetail blue">
              {{ item.courseClassVOs[1].currentDuration }}天
            </div>
          </div>
        </div>
        <div class="priceDetailsBuy">
          <div class="basePrice">套餐原价：${{ item.comboOriginalPrice }}</div>
          <div style="color: rgba(222, 19, 6, 1); font-size: 14px">
            <span style="font-size: 18px; font-weight: bold"
              >${{ item.comboDiscountPrice }}</span
            >
          </div>
<!--          <div class="extendQA">答疑时间{{ item.currentQaDuration }}天</div>-->
          <div class="buyNow" @click="buyNow(item)">立即购买</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseClassCombo } from "@/service/index";
export default {
  name: "courseMeal",
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (!this.id) return;
      getCourseClassCombo(this.id).then((res) => {
        if (res.code === 200) {
          this.dataList = res.result;
        }
      });
    },
    buyNow(item) {
      sessionStorage.setItem("calcOrder", JSON.stringify(item));
      sessionStorage.setItem("calcData", JSON.stringify(this.dataList));
      this.$router.push({ path: "/confirm?type=2" });
      console.log(item);
    },
  },
};
</script>

<style scoped>
.priceDetailsBuy {
    display: inline-block;
    margin-left: 138px;
    padding-left: 60px;
    border-left: 1px dashed #979797;
    height: 260px;
    vertical-align: top;
    margin-top: 20px;
    width: 200px;
    text-align: right;
}

.priceDetailsBuy .basePrice {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
    font-size: 14px;
    margin-top: 50px;
}

.priceDetailsBuy .baseQA {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
    font-size: 14px;
    margin-top: 24px;
}

.priceDetailsBuy .extendQA {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #de1306;
    line-height: 21px;
}

.priceDetailsBuy .buyNow {
    width: 128px;
    height: 36px;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-image: linear-gradient(to right, #ee1f2c, #fab677);
    border-radius: 18px;
    display: inline-block;
    cursor: pointer;
    margin-top: 24px;
}
.className {
  margin: 12px 0;
}
</style>
